
.ant-layout-header, .ant-layout-sider, .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: #181717;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: darken(#181717,2%);
}
.ant-layout-sider-trigger {
  background: lighten(#181717, 2%);
}

#root {
  width: 100%;
  height: 100%;
  > div {
    width: 100%;
    height: 100%;
    &.app_loading{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.app_header {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 4;
}

.container {
  width: 100%;
  &.container__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }
}

.app_loading_nflex{
  width: 100%;
  height: 100%;
}

.app_view {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.profile__avatar {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 160px;
  -moz-border-radius: 160px;
  border-radius: 160px;
  background-repeat: no-repeat;
  background-size: cover;
}

[for="upload_form_properties.copyright"], [for="edit_form_properties.copyright"] {
  font-weight: 600;
}

.grid-filter {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 12px 12px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 12px;
  max-width: 1280px;
}

.grid-filter.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-filter.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-item {
  .grid--label {
    font-weight: 600;
    margin-bottom: 4px;
  }
}


.btn--row {
  display: flex;
  flex-direction: row;
  align-items: center;
  button + button {
    margin-left: 8px;
  }
}

.excel--uploader {
  position: relative;
  .uploader--progress {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
  }
}

.contact--red {
  background-color: #FF0000;
}

.main--header {
  display: flex;
  flex-direction: row;
  align-items: center;
  .ant-menu {
    flex: 1;
    margin-left: 24px;
    .ant-menu-item {
      border-radius: 4px;
    }
  }
  .main--header__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }
  .main--header__user {
    display: flex;
    flex-direction: row;
    align-items: center;

    .user--info {
      color: #FFF;
      margin-right: 10px;
      .ant-avatar {
        margin-right: 10px;
      }
    }
  }
}

.ant-table-body .chidden {
  display: none;
}

.btn--row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-wrp {
  margin-top: 20px;
}

.app-wrp__buttons {
  margin-top: 20px;
  button + button {
    margin-left: 20px;
  }
}

.lagRadar {
  position: fixed;
  bottom: 10px;
  right: 10px
}

.catalog__form--block {
  display: flex;
  flex-direction: column;
  width: 100%;
  .catalog--form__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
    gap: 12px;
    > div {
      flex: 1;
      input, select {
        width: 100%;
      }
      &.btn--column {
        max-width: 50px;
      }
    }
  }
}

/*catalog.scss*/
.catalog.catalog_view {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 0 20px;
  .catalog--content__wrp {
    margin: 12px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px 0;
  }
}

.shop__card.catalog__card {
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  background-color: #FFF;
  border-radius: 0.5rem;
  overflow: hidden;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  padding: 0.5rem;
  grid-gap: 12px;
  .shop__card--cover {
    img {
      width: auto;
      max-height: 120px;
      height: auto;
    }
    .cover__empty {
      width: 120px;
      height: 120px;
      background-color: #dfdfdf;
      border-radius: 0.5rem;
    }
  }
  .shop__card--actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 2px;
  }
}


body > iframe {
  display: none;
}

.view--group__wrapper {
  background-color: #FFF;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.delivery--sortable__header {
  background-color: #FFF;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 2px;
  z-index: 2;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 2px 0;
}