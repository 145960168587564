.controller.manage {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .block {
    &.block--info {
      .ant-descriptions-view {
        background: #FFF;
        padding: 12px;
        border-radius: 4px;
      }
    }
  }

  .info--title_wrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .block--contacts {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.delivery--filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  .wrapper {
    margin-left: 12px;
    background: #FFF;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    padding: 4px 8px;
    flex: 1;
    display: flex;
    column-gap: 12px;
    flex-direction: row;
    align-items: center;
  }
}