@import 'base';
@import 'sider';
@import "components/req";
@import "projects/req";
@import "views/req";
@import "components/converter";

.ant-alert-error {
  background-color: #FF0000;
  border: 1px solid #ffa39e;
  color: #FFF;
}


.material--preview {
  display: flex;
  flex-direction: column;
  .btn--group {
    margin-bottom: 12px;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}


.media--view .media__item a.preview > div.image--expired, .media--list_item .image--expired {
  position: absolute;
  top: 28px;
  left: 4px;
  margin-left: 0 !important;
  border-radius: 4px;
  font-size: 12px;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #f50;
}

.media--list_item .image--expired {
  top: 4px;
}

.categories__report {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  .categories__report--item {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    padding: 8px 12px;
    .category__title {
      font-weight: 600;
      font-size: 16px;
    }
    & + .categories__report--item {
      margin-left: 20px;
    }
  }
}


.preview--container {
  .preview--btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .preview--container__iframe {
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    transition: width 0.3s ease 0s;
    margin: 0 auto;
    iframe {
      border: 0;
      width: 100%;
      height: 800px;
      margin: 0 auto;
    }
  }
}

.lg_input_text {
  display: flex;
  flex-direction: row;
  & + .lg_input_text {
    margin-top: 8px;
  }
  .ant-avatar {
    margin-right: 8px;
  }
}


.form_upload__item {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 12px;
  .input--label {
    margin-bottom: 12px;
  }
  & + .form_upload__item {
    margin-top: 12px;
  }

    .fields, .lg_input_file {
      display: flex;
      flex-direction: row;
      .ant-avatar {
        margin-right: 8px;
      }
      & + .lg_input_file {
        margin-top: 8px;
      }
    }

  .input--wrap {
    width: 100%;
  }

  .media_input--wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      width: 100%;
    }
  }
}

.file--control__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 4px;
  border-radius: 6px;
  background: #FFF;
  .file--name {
    padding-right: 10px;
  }
}


.cover.transcoder {
  position: relative;
}

.transcoder__progress {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  .transcode__percent {
    font-size: 2rem;
    font-weight: 600;
  }
}

.videojs {
  width: 100%;
  .videojs-player, .videojs-player video, .video-js {
    width: 100%;
  }
}

.import--controller {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  .import--controller__wrapper {
    background-color: #FFF;
    border-radius: 5px;
    padding: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  }
}


.form--contact__row {
  display: grid;
  grid-template-columns: 8fr 1fr;
  grid-gap: 8px;
  margin-bottom: 8px;
}

.coords--actions {
  margin: 10px 0;
}

.controller.manage{
  overflow: hidden;
  max-width: 100%;
  .block.block--info, .block.block--contacts {
    width: 100%;
  }
}

.modal--wrapper__scroll {
  width: 100%;
  overflow: hidden;
  table {
    font-size: 0.7rem;

  }
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    padding: 2px 4px;
  }
}

.users--online {
  margin-right: 20px;
  padding: 4px 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  height: 40px;
  box-sizing: border-box;
  line-height: 0;
  .ant-avatar + .ant-avatar {
    margin-left: 4px;
  }
}