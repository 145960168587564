


  .logo {
    background-color: transparent;
    background-image: url('/images/logos/rt.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 40px;
    width: 40px;
    margin: 10px auto;
    border-radius: 4px;
  }
